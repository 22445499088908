import moment from "moment";
import { BASE_URL } from "@/service_urls";

export function deepClone(obj) {
  return JSON.parse(JSON.stringify({ ...obj }));
}

export function isObject(a) {
  return !!a && a.constructor === Object;
}

export function isEmptyObjectCheck(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}


export function getFilterQueryStringWithoutArrayBasedOnSchema(pageName, filter, schema) {
  let filterKeys = Object.keys(filter);
  if (filterKeys.length) {
    let result = filterKeys.reduce((acc, item) => {
      if (filter[item]?.length) {
        let filteredIds
        const jobBoardNames = [
          "JobList",
          "Public Job Board",
          "JobListNew",
          "JobBoardByStatus",
          "Job Board",
          "Public Org Based Job Board"
        ]
        const queryKey = schema[item]?.["queryKey"];
        if (queryKey && queryKey.substr(queryKey.length - 9) === "__overlap") {
          filteredIds = `{${filter[item]}}`;
        } else {
          filteredIds = filter[item].toString();
        }
        if (item == "experience") {
          const fieldsName = schema[item]["queryKey"].split("__");
          const mValue = filter[item][0].split("-")
          let queryValue = "";
          fieldsName.forEach((ele, index) => {
            queryValue += `${ele}=${mValue[index]}&`
          });
          filteredIds = queryValue.slice(0, -1)
          acc += `${filteredIds}&`;
        } else if (item === "cpdCourse") {
          const fieldsName = schema[item]["queryKey"].split("___");
          const rawValue = filter[item][0];
          if (rawValue === 'all') {
            acc += '';
          } else if (rawValue === 'all_cpd') {
            acc += 'cpd_points__isnull=false&'
          } else if (rawValue === 'all_non_cpd') {
            acc += 'cpd_points__isnull=true&'
          } else {
            const mValue = filter[item][0].split("-")
            let queryValue = "";
            fieldsName.forEach((ele, index) => {
              queryValue += `${ele}=${mValue[index]}&`
            });
            filteredIds = queryValue.slice(0, -1)
            acc += `${filteredIds}&`;
          }
        } else if (["courseTitle", "locationRegion", "preferredLocations"].includes(item) 
            && /^[a-zA-Z]+$/.test(filter[item][0])) {
          const rawValue = filter[item][0];
          if (rawValue === 'all') {
            acc += '';
          }
        } else {
          acc += `${schema[item] ? schema[item]["queryKey"] : item
          }=${filteredIds}&`;
        }
      }
      return acc;
    }, "");
    return result.substring(0, result.length - 1);
  }
  return "";
}

export function getFilterQueryString(filter) {
  let filterKeys = Object.keys(filter);
  let result = filterKeys.reduce((acc, item) => {
    if (filter[item]?.length) {
      let filteredIds = filter[item].toString();
      acc += `${item}=[${filteredIds}]&`;
    }
    return acc;
  }, "");
  return result.substring(0, result.length - 1);
}

export function getFilterQueryStringWithoutArray(filter) {
  let filterKeys = Object.keys(filter);
  let result = filterKeys.reduce((acc, item) => {
    if (!(item == 'candidate_type_id__in' && filter[item] == 0)) {
      let filteredIds = filter[item]?.toString();
      acc += `${item}=${filteredIds}&`;
    }
    return acc;
  }, "");
  return result.substring(0, result.length - 1);
}

export function truncate(string, size) {
  return string.length > size ? string.slice(0, size - 1) + "…" : string;
}

export function queryAssignSelectedData(data, query) {
  for (let key in query) {
    if (query.hasOwnProperty(key) && data.hasOwnProperty(key)) {
      let arrayToStr = query[key].replace(/]|\[/g, "");
      let queryIdsResult = [...arrayToStr.split(/,\s*/)];
      let options = data[key]["options"];
      let selected = options.filter(val => queryIdsResult.includes(val.code.toString()));
      data[key]["selected"] = selected;
    }
  }
  if (query["ageLimit"]) {
    data["ageLimit"]["selected"] = JSON.parse(query["ageLimit"]);
  }
  return data;
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const getScope = () => {
  let userData = JSON.parse(localStorage.getItem("userData")) || {};
  if (userData && userData.access_token) {
    let userDetails = parseJwt(userData.access_token || "")
    return userDetails && userDetails.scopes.length ? userDetails.scopes[0] : "";
  } else {
    return ""
  }
}

export const getCustomerId = () => {
  let userData = JSON.parse(localStorage.getItem("userData"))
  let userDetails = userData ? parseJwt(userData.access_token) : null
  return userDetails?.customer_id ? Number(userDetails.customer_id) : null;
}

export const getCustomerType = () => {
  let userData = JSON.parse(localStorage.getItem("userData"))
  let userDetails = userData ? parseJwt(userData.access_token) : null
  return userDetails?.customer_type_id ? Number(userDetails.customer_type_id) : null;
}

export function createUUID() {
  var s = [];
  var hexDigits = '0123456789abcdef';
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  var uuid = s.join('');
  return uuid;
}

export const CANDIDATE_URL = `/candidate-list?active=%5Btrue,null%5D&deleted=%5Bfalse%5D`;
export const FACILITY_CANDIDATE_URL = `/candidate-list`;
export const TFW_CANDIDATE_URL = `/candidate-list/super_customer`;

export const Role = Object.freeze({
  systemAdmin: "system_admin",
  customerAdmin: "customer_admin", // Facility admin
  customerRecruiter: "customer_recruiter",
  customerCandidate: "customer_candidate",
  systemCandidate: "system_candidate",
  systemRecruiter: "system_recruiter",
  customerUser: "customer_user",
  jobAdvertiser: "job_advertiser",
  admin: ["system_admin", "customer_admin"],
  recruiter: ["system_recruiter", "customer_recruiter"],
  candidateRole: ["system_candidate", "customer_candidate"],
  customerUserRole: ["candidate_reviewer", "candidate_interviewer", "job_requisitioner", "job_authoriser"]
})

export const getRoleType = () => {
  const currentUser = getScope();
  let id = null;
  switch (currentUser) {
    case 'system_admin':
      id = 4;
      break;
    case 'customer_admin':
      id = 5;
      break
    default:
      break
  }
  return id;
}

export const pendoInit = (payload) => {
  return pendo.initialize({
    visitor: {
      id: payload.user_uuid,   // Required if user is logged in
      email: payload.email, // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      role: payload.user_role     // Optional


      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: payload.customer_uuid // Highly recommended
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    }
  });
}

export function getDefaultBookmark(path) {
  let obj = {
    url: "",
    filter_name: "Default",
    isDefault: true
  };
  const currentUserRoleID = getRoleType();
  switch (path) {
    case "/candidate-list":
      if (currentUserRoleID === 4) {
        obj['url'] = CANDIDATE_URL;
      } else {
        obj['url'] = FACILITY_CANDIDATE_URL;
      }
      return obj
      break;
    default:
      return {}
  }
}

export const getLocalOrgDetail = () => {
  return Object(JSON.parse(localStorage.getItem("orgDetail")))
}

export function LOGIN_URL() {
  let orgDetail = JSON.parse(localStorage.getItem("orgDetail"));
  let path_acronym = orgDetail?.path_acronym;
  return String(path_acronym != undefined ? `/${path_acronym}` : "/");
}

export const IS_DOH_THEME = process.env.VUE_APP_THEME_NAME == "doh" ? true : false;
export const VUE_APP_RELEASE_VERSION = process.env.VUE_APP_RELEASE_VERSION;
export const VUE_APP_LAST_DEPLOYED = process.env.VUE_APP_LAST_DEPLOYED;


export const getRoleScopes = () => {
  let userData = JSON.parse(localStorage.getItem("userData")) || {};
  if (userData && userData.access_token) {
    let userDetails = parseJwt(userData.access_token || "")
    if (userDetails && userDetails.scopes.length)
      userDetails.scopes = userDetails.scopes.sort((a, b) => a === Role.customerRecruiter ? -1 : 1);
    return userDetails && userDetails.scopes.length ? userDetails.scopes : [];
  } else {
    return []
  }
}

export const getUserRoleScopes = () => {
  let userData = JSON.parse(localStorage.getItem("userData")) || {};
  if (userData && userData.access_token) {
    const { user_roles } = parseJwt(userData.access_token || "")
    if (user_roles && user_roles.length)
      return user_roles;
  } else {
    return []
  }
}

export function appendAccessToken(url) {
  let userData = JSON.parse(localStorage.getItem("userData")) || {};
  if (url && userData?.access_token) {
    const token = encodeURIComponent(userData?.access_token)
    return url.includes("?") ? `${url}&token=${token}` : `${url}?token=${token}`
  }
  return url;
}

export function getDaysFromDate(date) {
  if (!date) return "";
  let todate = new Date().toISOString().split('T')[0]
  let currentDate = moment(todate, 'YYYY-MM-DD');
  let expiryDate = moment(date, 'YYYY-MM-DD');
  if (expiryDate < currentDate) return "";
  let diff = expiryDate.diff(currentDate, 'days');
  if (date) return diff;
  return '--';
}

export function getBorderColor(days) {
  if (!Number.isFinite(days)) return 'none';
  if (days < 0) return '#dcd5d5';
  if (days == 0 || days == 1 || days == 2) return '#ff0000'
  if (days > 2 && days <= 4) return '#FFBF00'
  if (days >= 5) return '#008000'
  return 'none'
}

export const Variant = Object.freeze({
  campaign: "CAMPAIGN",
  basic: "BASIC",
  standard: "STANDARD",
  enterprise: "ENTERPRISE",
})

export function getRAGColorsCircle(days) {
  if (!Number.isFinite(days)) return 'none';
  if (days < 0) return '#dcd5d5';
  if (days == 0 || days == 1 || days == 2) return '#ff0000'
  if (days > 2 && days <= 4) return '#FFBF00'
  if (days >= 5) return '#008000'
  return 'none'
}

export function getRAGColorsForJobExpiryCircle(days) {
  if (!Number.isFinite(days)) return '';
  if (days <= 7) return '#ff0000'
  if (days > 7 && days <= 15) return '#FFBF00'
  if (days > 15) return '#008000'
  return ''
}

export function getRAGInterviewColorsCircle(days) {
  if (!Number.isFinite(days)) return '';
  if (days < 0) return '#dcd5d5';
  if (days == 0 || days == 1) return '#ff0000';
  if (days > 1 && days < 3) return '#FFBF00';
  if (days >= 3) return '#008000';
  return '';
}

// RabbitMQ
export const MQTT_USERNAME = process.env.VUE_APP_MQTT_USERNAME;
export const MQTT_PASSWORD = process.env.VUE_APP_MQTT_PASSWORD;
export const ROUTING_KEY = process.env.VUE_APP_ROUTING_KEY;

export const actionableItem = Object.freeze({
  jobApproval: { status_id: 28, sub_status_id: 73 },
  jobReApproval: { status_id: 28, sub_status_id: 72, days: 0 },
  jobDisApproval: { status_id: 28, sub_status_id: 74 },
  resubmit: { sub_status_id: 44 },
  jobComplete: { status_id: 29, sub_status_id: null, reviewed: false, isJobSupplierFromAccessToken: false },
  reInstate: { status_id: 52, sub_status_id: 47 },
  reSubmit: { status_id: 52, sub_status_id: 44 },
  reqInterview: { status_id: 54, sub_status_id: 50 },
  RereqInterview: { status_id: 54, sub_status_id: 56 },
  interviewSchedule: { status_id: 54, sub_status_id: null },
  instateOffer: { status_id: 54, sub_status_id: 57 },
  instateOnboard: { status_id: 58, sub_status_id: 60 },
  initiateJoin: { status_id: 62, sub_status_id: 69, onBoardAccepted: true },
  markInactive: { status_id: 28, sub_status_id: 74 },
});

export const CONSTANT = Object.freeze({
  // Candidate type id
  PHYSICIAN: 1,
  NURSE: 2,
  AHP: 3,
  DENTIST: 4,
  NON_CLINIC_ADMIN: 6,

  // Document Type ID
  ORG_LOGO_DOC_TYPE: 17,
  JOB_PICTURE_DOC_TYPE: 19,

  // Advertisement Locations
  HOSPITAL_JOB_BOARD: "hospital_job_board",
  SOCIAL_MEDIA: "social_media",
  GROUP_JOB_BOARD: "group_job_board",

  // Template display Names
  ORGANISATION_DESCRIPTION: "Organisation Description",

  // Organisation types
  ORG_TYPES: {
    hospital: 1,
    agency: 3,
    partner_hospital: 16,
    university_training: 23,
    vocational_training: 24,
    partner_agency: 13,
  },
  // Customer types
  CUSTOMER_TYPES: {
    super_hospital: 1,
    agency: 2,
    educational_institute: 5,
    language_school: 6,
  },

  // Job Approval Status
  JOB_DRAFT_STATUS: 27,
  JOB_PENDING_STATUS: 28,
  JOB_ACTIVE_STATUS: 29,
  JOB_INACTIVE_STATUS: 30,
  JOB_ARCHIVE_STATUS: 31,

  // STATUS
  JOB_AWAITING_APPROVAL_STATUS: 72,
  JOB_ON_HOLD_STATUS: 73,
  JOB_DISAPPROVED_STATUS: 74,

  // Customer Type
  CUSTOMER_TYPE: {
    recruiter_agency: 2,
  },
  CUSTOMERS: {
    super_customer: 26,
  },
  ORGANISATIONS: {
    super_customer: 148,
  },

  //Partner info
  partners: [
    {
      partner_id: 1,
      img: "/img/tfw/profile-page/slc-header.png",
      name: "SLC",
      component: "SLCProfile",
      thumbnail: "/img/tfw/profile-page/slc.png",
    },
    {
      partner_id: 2,
      img: "/img/tfw/profile-page/doh-banner.png",
      name: "Department of Health - United Arab Emirates",
      component: "DOHProfile",
      thumbnail: "/img/tfw/profile-page/doh.png",
    },
    {
      partner_id: 3,
      img: "/img/tfw/profile-page/nhs-banner.png",
      name: "NHS Foundation Trust",
      component: "NHSProfile",
      thumbnail: "/img/tfw/profile-page/nhs.png",
    },
  ],

  //Course info
  courses: [
    {
      course_id: 1,
      img: "/img/tfw/profile-page/course/course_1.png",
      name: "REACH OET B NURSING",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_1.png",
      filter_names: "LANGUAGE TRAINING",
      btnPosition: [
        { x: { start: 314, end: 434 }, y: { start: 250, end: 274 } },
        { x: { start: 264, end: 366 }, y: { start: 1459, end: 1484 } },
        { x: { start: 531, end: 633 }, y: { start: 1459, end: 1485 } },
        { x: { start: 798, end: 898 }, y: { start: 1558, end: 1581 } },
      ],
    },
    {
      course_id: 2,
      img: "/img/tfw/profile-page/course/course_2.png",
      name: "IELTS PREPARATION FOR DOCTORS",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_2.png",
      filter_names: "LANGUAGE TRAINING",
      btnPosition: [
        { x: { start: 319, end: 423 }, y: { start: 276, end: 304 } },
        { x: { start: 367, end: 440 }, y: { start: 934, end: 951 } },
        { x: { start: 683, end: 754 }, y: { start: 934, end: 951 } },
      ],
    },
    {
      course_id: 3,
      img: "/img/tfw/profile-page/course/course_3.png",
      name: "REACH OET B MEDICINE",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_3.png",
      filter_names: "LANGUAGE TRAINING",
      btnPosition: [
        { x: { start: 323, end: 441 }, y: { start: 256, end: 283 } },
        { x: { start: 264, end: 366 }, y: { start: 1459, end: 1484 } },
        { x: { start: 531, end: 633 }, y: { start: 1459, end: 1485 } },
        { x: { start: 798, end: 898 }, y: { start: 1558, end: 1581 } },
      ],
    },
    {
      course_id: 4,
      img: "/img/tfw/profile-page/course/course_4.png",
      name: "ENGLISH FOR NURSES",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_4.png",
      filter_names: "LANGUAGE TRAINING",
      btnPosition: [
        { x: { start: 316, end: 434 }, y: { start: 270, end: 295 } },
        { x: { start: 234, end: 343 }, y: { start: 1425, end: 1445 } },
        { x: { start: 517, end: 623 }, y: { start: 1421, end: 1445 } },
        { x: { start: 798, end: 904 }, y: { start: 1549, end: 1571 } },
      ],
    },
    {
      course_id: 5,
      img: "/img/tfw/profile-page/course/course_5.png",
      name: "REACH IELTS",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_5.png",
      filter_names: "LANGUAGE TRAINING",
      btnPosition: [
        { x: { start: 527, end: 643 }, y: { start: 254, end: 280 } },
        { x: { start: 186, end: 314 }, y: { start: 1405, end: 1430 } },
        { x: { start: 520, end: 647 }, y: { start: 1405, end: 1429 } },
        { x: { start: 854, end: 975 }, y: { start: 1520, end: 1549 } },
      ],
    },
    {
      course_id: 6,
      img: "/img/tfw/profile-page/course/course_6.png",
      name: "REACH IELTS Writing",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_6.png",
      filter_names: "LANGUAGE TRAINING",
      btnPosition: [
        { x: { start: 314, end: 432 }, y: { start: 246, end: 272 } },
        { x: { start: 147, end: 290 }, y: { start: 1381, end: 1412 } },
        { x: { start: 512, end: 655 }, y: { start: 1385, end: 1413 } },
        { x: { start: 876, end: 1015 }, y: { start: 1512, end: 1543 } },
      ],
    },
    {
      course_id: 7,
      img: "/img/tfw/profile-page/course/course_7.png",
      name: "REACH OET B NURSING",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_7.png",
      filter_names: "LANGUAGE TRAINING",
      btnPosition: [
        { x: { start: 321, end: 439 }, y: { start: 261, end: 287 } },
        { x: { start: 263, end: 366 }, y: { start: 1452, end: 1472 } },
        { x: { start: 532, end: 635 }, y: { start: 1452, end: 1475 } },
        { x: { start: 800, end: 903 }, y: { start: 1559, end: 1581 } },
      ],
    },
    {
      course_id: 8,
      img: "/img/tfw/profile-page/course/course_8.png",
      name: "ENGLISH FOR PHARMACY",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_8.png",
      filter_names: "LANGUAGE TRAINING",
      btnPosition: [
        { x: { start: 315, end: 435 }, y: { start: 270, end: 297 } },
        { x: { start: 218, end: 333 }, y: { start: 1396, end: 1420 } },
        { x: { start: 519, end: 635 }, y: { start: 1396, end: 1420 } },
        { x: { start: 822, end: 934 }, y: { start: 1535, end: 1561 } },
      ],
    },
    {
      course_id: 9,
      img: "/img/tfw/profile-page/course/course_9.png",
      name: "REACH OET B MEDICINE",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_9.png",
      filter_names: "LANGUAGE TRAINING",
      btnPosition: [
        { x: { start: 312, end: 430 }, y: { start: 264, end: 289 } },
        { x: { start: 218, end: 334 }, y: { start: 1393, end: 1412 } },
        { x: { start: 511, end: 622 }, y: { start: 1397, end: 1413 } },
        { x: { start: 803, end: 908 }, y: { start: 1538, end: 1561 } },
      ],
    },
    {
      course_id: 10,
      img: "/img/tfw/profile-page/eintegrity.jpg",
      name: "STEP TO WORK - AHP",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_10.png",
      filter_names: "HEALTH TRAINING",
    },
    {
      course_id: 11,
      img: "/img/tfw/profile-page/eintegrity.jpg",
      name: "ONLINE RADIOLOGY COURSE",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_11.png",
      filter_names: "HEALTH TRAINING",
    },
    {
      course_id: 12,
      img: "/img/tfw/profile-page/eintegrity.jpg",
      name: "ONLINE ANAESTHESIA COURSE",
      thumbnail: "/img/tfw/profile-page/course/thumbnails/slc_tc_12.png",
      filter_names: "HEALTH TRAINING",
    },
  ],

  // Partner Profile
  partnerProfiles: [
    {
      id: 1,
      image: "/img/tfw/profile-page/partner_1.png",
      view_image: "/img/tfw/profile-page/doh.png",
      title: "Health Department – United Arab Emirates",
      sub_title:
        "Leading Regional Health Authority in the United Arab Emirates.",
      badges: ["Consulting", "Jobs", "Training", "Licensure"],
      filter_names: "MEDICAL FACILITIES",
      urls: [
        "https://www.doh.gov.ae/en/research/landing-page ",
        "https://www.doh.gov.ae/en/eservices/#Public1",
        "https://www.doh.gov.ae/en/investor/investment",
      ],
      btnPosition: [
        { x: { start: 215, end: 394 }, y: { start: 332, end: 356 } },
        { x: { start: 406, end: 587 }, y: { start: 333, end: 358 } },
        { x: { start: 599, end: 778 }, y: { start: 334, end: 359 } },
      ],
    },
    {
      id: 2,
      image: "/img/tfw/profile-page/partner_2.png",
      view_image: "/img/tfw/profile-page/nhs-test.png",
      title: "Children’s Hospital – United Kingdom",
      sub_title: "World famous children's hospital in the northwest of England",
      badges: [
        "Jobs",
        "Training",
        "Consulting",
        "Placements",
        "Acute & Speciality",
        "Regional Center",
      ],
      filter_names: "GOVERNMENTAL",
      urls: [
        "https://www.alderhey.nhs.uk/alder-hey-innovation/",
        "https://stage.talentfind.io/tfw/job-board/26",
        "https://www.alderhey.nhs.uk/services/",
      ],
      btnPosition: [
        { x: { start: 173, end: 295 }, y: { start: 454, end: 482 } },
        { x: { start: 445, end: 569 }, y: { start: 455, end: 483 } },
        { x: { start: 582, end: 702 }, y: { start: 454, end: 482 } },
      ],
    },
    {
      id: 3,
      image: "/img/tfw/profile-page/partner_3.png",
      view_image: "/img/tfw/profile-page/slc-thumbnail.png",
      title: "Language Training",
      sub_title: "The UK’s Leading Medical English Language Provider",
      badges: ["Training", "Consulting", "English Language", "Medical"],
      filter_names: "TRAINING PROVIDERS",
      btnPosition: [
        { x: { start: 205, end: 375 }, y: { start: 344, end: 370 } },
        { x: { start: 387, end: 557 }, y: { start: 344, end: 367 } },
        { x: { start: 571, end: 735 }, y: { start: 346, end: 366 } },
      ],
      urls: [
        "https://stage.talentfind.io/tfw/course/1",
        "https://stage.talentfind.io/tfw/course/5",
        "https://stage.talentfind.io/tfw/course/4",
      ],
    },
    {
      id: 4,
      image: "/img/tfw/profile-page/partner_4.png",
      view_image: "/img/tfw/profile-page/eintegrity.jpg",
      title: "Health and Care e-Learning",
      sub_title:
        "Courses developed by the UK's royal colleges and Health Education England",
      badges: ["Training", "Consulting", "Collaborations"],
      filter_names: "TRAINING PROVIDERS",
    },
  ],
});

export function getSkipLimitBypage(pageNumber = 1, pageLimit = 10) {
  const skip = (pageNumber - 1) * pageLimit || 0;
  const limit = pageLimit;
  return { skip, limit };
}

export function getPageNumberBySkipLimit(skip = 0, limit = 10) {
  return Math.ceil(skip / limit);
}

export function getPageNumberByIndexSkipLimitTotal({
  skip,
  limit,
  index,
  total,
}) {
  if (index >= 0) {
    //Formula = (skip-total+index+1)/limit
    return Math.ceil((skip - total + index + 1) / limit);
  }
  return 1;
}

export function getPositiveActionIds({ status_id, sub_status_id }) {
  const positiveAction = [
    { status_id: 67, sub_status_id: 71, action_id: 14 }, //Submit
    { status_id: 54, sub_status_id: 50, action_id: 6 }, //Interview
    { status_id: 54, sub_status_id: 51, action_id: 6 }, //Interview
    { status_id: 54, sub_status_id: 56, action_id: 6 }, //Interview
    { status_id: 54, sub_status_id: 57, action_id: 7 }, //Offer
    { status_id: 58, sub_status_id: 60, action_id: 8 }, //Onboard
    { status_id: 62, sub_status_id: 69, action_id: 1 }, //Boarded
  ];
  const res = positiveAction.filter((v) => {
    let r = true;
    if (status_id) r = r && v?.status_id === status_id;
    if (sub_status_id) r = r && v?.sub_status_id === sub_status_id;
    return r;
  });
  return res.map((v) => v?.action_id);
}

export const getCurrentTimeStamp = moment().format("DDMMYYYY_hhmmss");

export function isMobile() {
  return window.matchMedia("only screen and (max-width: 420px)").matches;
}

export function convertOptionsToTreeOptions(options) {
  let r =
    (options &&
      _.reduce(
        options,
        function (result, value, key) {
          result && result.length ? null : (result = []);
          if (value?.group_by) {
            let _groupByindex = _.findIndex(result, {
              label: value?.group_by,
            });
            let sub_spl_label = value?.sub_speciality;
            let spl_label = value?.speciality;
            const cCode = value?.speciality_reference_group
              ? value?.speciality_reference_group
              : value?.code;
            if (_groupByindex < 0) {
              let opt = {
                id: `parent-[${cCode}]`,
                label: value?.group_by,
                checkBox: false,
                children: [
                  {
                    id: value?.code,
                    label: spl_label,
                    checkBox: true,
                    spl_label,
                    sub_spl_label,
                  },
                ],
                isParent: true,
              };
              if (value?.group_by.includes("Sub Speciality")) {
                opt = {
                  ...opt,
                  children: [
                    {
                      id: `[${value?.code}]`,
                      label: spl_label,
                      spl_label,
                      sub_spl_label,
                      checkBox: true,
                      children: [
                        {
                          id: value?.code,
                          label: sub_spl_label,
                          spl_label,
                          sub_spl_label,
                        },
                      ],
                    },
                  ],
                };
              }
              result.push(opt);
            } else {
              if (!result[_groupByindex].id.includes(cCode))
                result[_groupByindex].id += `,${cCode}`;

              let _Cindex = _.findIndex(result[_groupByindex].children, {
                label: spl_label,
              });
              if (_Cindex < 0) {
                let ccData = {
                  id: value?.code,
                  label: spl_label,
                  sub_spl_label,
                  spl_label,
                  checkBox: true,
                };
                if (value?.group_by.includes("Sub Speciality")) {
                  ccData = {
                    id: `[${value?.code}]`,
                    label: spl_label,
                    sub_spl_label,
                    spl_label,
                    checkBox: true,
                    children: [
                      {
                        id: value?.code,
                        label: sub_spl_label,
                        sub_spl_label,
                        spl_label,
                        checkBox: true,
                      },
                    ],
                  };
                }

                result[_groupByindex].children.push(ccData);
              } else {
                if (!result[_groupByindex].children[_Cindex].children) {
                  let _Cvalue = result[_groupByindex].children[_Cindex];
                  let _cId = value?.speciality_reference_group
                    ? value?.speciality_reference_group
                    : _Cvalue.id;
                  result[_groupByindex].children[_Cindex] = {
                    id: _cId,
                    label: spl_label,
                    checkBox: true,
                    children: [
                      {
                        id: _Cvalue.id,
                        label: _Cvalue.sub_spl_label,
                        spl_label: _Cvalue.spl_label,
                        sub_spl_label: _Cvalue.sub_spl_label,
                        checkBox: true,
                      },
                    ],
                  };
                }
                result[_groupByindex].children[_Cindex].children.push({
                  id: value?.code,
                  label: sub_spl_label,
                  spl_label,
                  sub_spl_label,
                });
                if (!value?.speciality_reference_group) {
                  result[_groupByindex].children[
                    _Cindex
                  ].id += `,${value.code}`;
                }
              }
            }
          }
          return result;
        },
        []
      ).sort((a, b) => {
        return a.label.includes("Main Speciality") ? -1 : 1;
      })) ||
    [];
  return r;
}

const hostURL = () => {
  // FOR LOCAL PREVIEW ONLY
  // const _baseURL = BASE_URL;
  // const _base_domain = _baseURL.split("/api")[0];

  // FOR LIVE WEBSITE
  const _base_domain = `${window.location.origin}`;

  return _base_domain;
};

export function generateFullUrlWithToken(link) {
  return appendAccessToken(`${hostURL()}${link}`);
}

export function appendBaseUrlWithLink(link) {
  return `${hostURL()}${link}`;
}

export function generateLocalFileUrl(fileUrl) {
  return `${hostURL()}/api/v1/customer/static/files/download?url=${fileUrl}&tt=${new Date().getTime()}`;
}
